import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Typography, Box, Divider } from '@material-ui/core';

import { getUpcomingConferences, items } from '../utils';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import EventsOverview from '../components/EventsOverview';

export const query = graphql`
  {
    api {
      conferences(orderBy: "-start_date") {
        edges {
          node {
            id
            conferenceType
            title
            slug
            startDate
            endDate
            icalUid
            series {
              title
              imageThumb
            }
          }
        }
      }
      upcomingEvents(calendar: "local", first: 2) {
        edges {
          node {
            id
            icalUid
            start {
              date
              dateTime
            }
            end {
              date
              dateTime
            }
            recurrence
            summary
            location
            description
          }
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const upcomingLocal = items(data.api.upcomingEvents);
  const conferences = items(data.api.conferences);

  const upcomingConferences = getUpcomingConferences(conferences);

  return (
    <Layout>
      <SEO title="Events" />
      <Box textAlign="center" mb={6}>
        <Typography variant="h1">Events</Typography>
      </Box>

      <Box mb={8}>
        <Divider />
      </Box>

      <EventsOverview local={upcomingLocal} conferences={upcomingConferences} />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;
